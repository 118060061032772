class Api {
    BASE_URL = 'https://service.forexprossystem.com';
    // BASE_URL = 'http://localhost:8000';
    API = '/api';
    APP_TOKEN = 'abc123';
    URL = this.BASE_URL + this.API;

    //send mail
    MAILER = this.URL + '/mailer';

    //add order
    ADD_ORDER = this.URL + '/order/add';
}

export default new Api();
