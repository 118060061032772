import './style.css';
import Paynow from "./views/Paynow";
import {
    Alert,
    Row,
    Col
} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Success from "./views/Success";
import Failed from "./views/Failed";
import appService from "./Helpers/Services";
import moment from 'moment';

function App() {
    const amount = 24.99;
    // const adminMail = ['majchinthaka@gmail.com', 'chinthakamaj@gmail.com'];
    const adminMail = ['asanka@attrex.lk', 'chamithri@attrex.lk'];
    const [downLink, setDownLink] = useState('');

    const [alertType, setAlertType] = useState('danger');

    const [show, setShow] = useState(false);
    const [showPay, setShowPay] = useState(false);

    const [showPayment, setShowPayment] = useState(true);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showFailed, setShowFailed] = useState(false);

    const [alertMsg, setAlertMsg] = useState('');

    const [formData, setFormData] = useState([]);

    function validation() {
        if(formData.name.trim() !== "" && formData.email.trim() !== "") {
            let re = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;

            if ( !re.test(formData.email) ) {
                setAlertMsg('Invalid Email');
                setShow(true);
                setShowPay(false);
            } else {
                setAlertMsg('');
                setShow(false);
                setShowPay(true);
            }
        } else {
            setAlertMsg('All fields are required');
            setShow(true);
            setShowPay(false);
        }
    }


    function createBody(name, order_id = 'N/A', dt, status) {
        const body = `<p>
                    <b>Hi,${name}</b>
                    <br>
                    <p>Your order has been completed!</p>
                    <p><b>Order Date Time: ${dt}</b></p>
                    <p><b>Order ID: ${order_id}</b></p>
                    <p><b>Paid: $ ${amount}</b></p>
                    <p><b>Status: ${status}</b></p>
                    <p><b>Your Download Link:</b><a href="https://service.forexprossystem.com/download/${order_id}"> Download</a></p>
                  </p>`;

        console.log(body);
        return body;
    }

    function createAdminBody(name, order_id = 'N/A', dt, status) {
        const body = `<p>
                    <b>Hi,${name}</b>
                    <br>
                    <p>New order has been received!</p>
                    <p><b>Order Date Time: ${dt}</b></p>
                    <p><b>Name: ${formData.name}</b></p>
                    <p><b>Email: ${formData.email}</b></p>
                    <p><b>Order ID: ${order_id}</b></p>
                    <p><b>Paid: $ ${amount}</b></p>
                    <p><b>Status: ${status}</b></p>
                  </p>`;

        return body;
    }

    const handleCallback = (data, orderData) =>{
        setShowPayment(data.payment);
        setShowSuccess(data.success);
        setShowFailed(data.failed);

        const order_id = orderData.purchase_units[0].payments.captures[0].id;
        const dt = moment(new Date(orderData.update_time).toUTCString()).format('YYYY-MM-DD h:mm:ss');

        if(data.success) {
            const dataOrder = {
                'name': formData.name,
                'email': formData.email,
                'contact_number': formData.mobile,
                'order_id': order_id
            };

            //store order details
            appService.add_order(dataOrder)
                .then(data => {
                    setDownLink(data.link);
                }).catch(e => console.log(e));

            const data = {
                'body': createBody(formData.name, order_id, dt, 'Success'),
                'to': formData.email,
                'subject': 'ForexPros System'
            };

            const dataAdmin = {
                'body': createAdminBody('Admin', order_id, dt, 'Success'),
                'to': adminMail,
                'subject': 'ForexPros System'
            };

            //send buyer email
            appService.send_mail(data)
                .then(data => {
                    // console.log('data');
                    // console.log(data.status);
                }).catch(e => console.log(e));

            //send admin email
            appService.send_mail(dataAdmin)
                .then(data => {
                    // console.log('data');
                    // console.log(data.status);
                }).catch(e => console.log(e));
        }
    }

    useEffect(() => {
        setFormData({...formData, name: '', email: ''});
    }, []);

    return (
        <div className="container-fluid">
            <div className="container">
                <Row>
                    <Col md={12}>
                        <div className="main-header text-center">
                            <img src="/logo.png" alt="aihe logo" className="img-fluid">
                            </img>
                        </div>
                    </Col>
                </Row>

                {showPayment ? <Row className="mt-5">
                    <Col md={12}>
                        <div className="caption-heading text-center">
                            <h1>ForexPros SYSTEM PAYMENT</h1>
                        </div>
                    </Col>
                </Row> : null}

                {showPayment ? <Row>
                    <div>
                        <form id="application">
                            <Col md={12} className="mx-auto form-sec">
                                {show ? <Alert variant={alertType}>{alertMsg}</Alert> : null}
                            </Col>

                            <Col md={12} className="mx-auto form-sec">
                                <input
                                    type="text"
                                    autoComplete="off"
                                    autoFocus={true}
                                    required
                                    className="form-control"
                                    id="name"
                                    max={100}
                                    placeholder="Name"
                                    onChange={e => {
                                        setFormData({...formData, name: e.target.value});
                                        validation();
                                    }}
                                >
                                </input>

                                <input
                                    type="email"
                                    required
                                    autoComplete="off"
                                    className="form-control"
                                    id="email"
                                    placeholder="Email"
                                    onChange={e => {
                                        setFormData({...formData, email: e.target.value});
                                        validation();
                                    }}
                                >
                                </input>
                            </Col>

                            <div className="row mt-5">
                                <div className="col-md-12">
                                    <div className="sub-heading text-center">
                                        <h1>Total Payment Amount</h1>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="payment-amount text-white text-center d-flex">
                                        <h3 className="py-2"><b>$</b></h3>
                                        <input type="text" readOnly={true} disabled={true} value={amount} autoComplete="false" className="form-control" id="amount" placeholder="Amount">
                                        </input>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div className="mt-5">
                        </div>

                        { showPay ? <Paynow value={amount} parentCallback={handleCallback}/> : null }
                    </div>

                    <Col>
                        <p style={{fontSize: '20px'}}>
                            Awake Your Real Billionaire Mind and take the next step forward!
                            Beat the market and earn like a pro!
                            Still Afraid? Don’t be!</p>

                        <div className="text-center">
                            <img style={{width: 300, height: 300}} src="/money_back.png" alt="aihe logo" className="img-fluid">
                            </img>
                        </div>

                        <p>
                            If you have an issue, you are not alone just let us know!
                            Risk Free 30 Days Money Back Guarantee Includes! If Unhappy Get a Refund!
                        </p>
                    </Col>
                </Row>: null}

                {showSuccess ? <Success downlink={downLink}/> : null}
                {showFailed ? <Failed /> : null}
            </div>
        </div>
    );
}

export default App;
