import axios from 'axios';
import api from './api';

class Services {

    //send mail
    send_mail(data) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['mail_token'] = api.APP_TOKEN;

            axios.post(api.MAILER, data)
                .then(result => {
                    if (result.status) {
                        resolve(result.data)
                    } else {
                        reject(result.data)
                    }
                }).catch(error => reject(error));
        });
    }

    add_order(data) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['mail_token'] = api.APP_TOKEN;

            axios.post(api.ADD_ORDER, data)
                .then(result => {
                    if (result.status) {
                        resolve(result.data)
                    } else {
                        reject(result.data)
                    }
                }).catch(error => reject(error));
        });
    }
}

export default new Services();