import React, { useState, useEffect } from "react";
import {Button, Col, Row} from "react-bootstrap";

function Failed(props) {
    return(
        <div>
            <Row className="mt-5">
                <Col md={12}>
                    <div className="caption-heading text-center">
                        <h1>Payment failed!</h1>

                        <a href="/">
                            <Button variant="success" style={{fontWeight: 600}}>Try again</Button>
                        </a>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Failed;