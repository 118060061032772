import React, { useState, useEffect } from "react";

function Paynow(props) {
    const paypal = window.paypal;
    const value = props.value;

    const onStatus = (data, orderData) => {
        props.parentCallback(data, orderData);
        // {payment: false, success: true, failed: false}
    }

    useEffect(() => {
        const element = document.getElementById('paypal-button-container');
        element.innerHTML = '';

        paypal.Buttons({
            style: {
                shape: 'rect',
                color: 'blue',
                layout: 'vertical',
                label: 'buynow',
            },

            createOrder: function(data, actions) {
                return actions.order.create({
                    purchase_units: [{"description":"FOREXPROS SYSTEM™","amount":{"currency_code":"USD","value":value}}]
                });
            },

            onApprove: function(data, actions) {
                return actions.order.capture().then(function(orderData) {

                    // Full available details
                    // console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));

                    // Show a success message within this page, e.g.
                    // element.innerHTML = '';
                    // element.innerHTML = '<h3>Thank you for your payment!</h3>';

                    // Or go to another URL:  actions.redirect('thank_you.html');

                    if(orderData.status === "COMPLETED")
                        onStatus({payment: false, success: true, failed: false}, orderData);
                    else
                        onStatus({payment: false, success: false, failed: true}, orderData);
                });
            },

            onError: function(err) {
                console.log(err);
            }
        }).render('#paypal-button-container');
    }, []);

    return (
        <div id="smart-button-container">
            <div style={{textAlign: 'center'}}>
                <div id="paypal-button-container">
                </div>
            </div>
        </div>
    );
}

export default Paynow;