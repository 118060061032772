import React, { useState, useEffect } from "react";
import {Button, Col, Row} from "react-bootstrap";

function Success(props) {

    const downLink = props.downlink;

    return(
        <div>
            <Row className="mt-5">
                <Col md={12}>
                    <div className="caption-heading text-center">
                        <h1>THANK YOU FOR YOUR ORDER!</h1>

                        <p>WE STRIVE TO DELIVER THE GEAT PRODUCTS AND SERVICES
                            HERE YOU CAN DOWLOAD THE BEST TRADING SYSTEM</p>

                        <Row className="mb-5">
                            <Col sm={6}>
                                <div className="text-center">
                                    <img style={{width: 100, height: 100}} src="/mt4.png" alt="mt4 logo" className="img-fluid">
                                    </img>
                                </div>
                                <a href={downLink}>
                                    <Button variant="success" style={{fontWeight: 600}}>DOWNLOAD FOR METATRADER 4</Button>
                                </a>
                            </Col>

                            <Col sm={6}>
                                <div className="text-center">
                                    <img style={{width: 100, height: 100}} src="/user_guide.png" alt="user_guide" className="img-fluid">
                                    </img>
                                </div>

                                <a href="/User manual Forexprossystem Revolution 2.0.1.pdf">
                                    <Button variant="outline-dark" style={{fontWeight: 600}}>DOWNLOAD USER GUIDE</Button>
                                </a>
                            </Col>
                        </Row>

                        <p>WE HAVE SEND AN EMAIL WITH ALL INSTRUCTIONS TO BEAT THE FINANCIAL MARKETS AND BECOME A SUSTAINABLE PROFIT MAKER! BE SURE TO CHECK YOUR INBOX AND SPAM FOLDERS ALSO!</p>

                        <p>
                            WE WILL BE IN TOCH WITH THE LATEST UPDATES AND NEWS <br/>
                            IF YOU HAVE ANY CLARIFICATION OR NEED AN EXTRA SUPPORT, SIMPLY SEND AN EMAIL TO US AT:<br/>
                            <a href="mailto:member@forexprossystem.com">member@forexprossystem.com</a>
                        </p>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Success;